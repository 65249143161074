import React, { useEffect, useState } from 'react';
import { BiMinusCircle, BiPlusCircle } from 'react-icons/bi';
import { Tab, TabList, TabPanel } from 'react-tabs';
import { useSystemContext } from '../../Context/SystemContext';
import Chart84 from '../../components/Chart84';
import Grid from '../../components/Grid';
import { Grid5W2H } from '../../components/Grid5W2H';
import { GridAnexos } from '../../components/GridAnexos';
import { GridDiario } from '../../components/GridDiario';
import {
    AttachmentInput,
    CepInput,
    CheckBoxInput,
    DBSelectInput,
    DateInput,
    FormInput,
    GroupInput,
    Input,
    MaskInput,
    SelectInput,
    TabsInput,
    TextInput,
    TimeInput,
} from '../../components/Input';
import { normalizeText } from '../../helper/Text';
import { dateToString } from '../../helper/dates';

const yup = require('../../interface/yup-br');

export const Form81 = () => {
    const System = useSystemContext();
    return (
        <div>
            <TabsInput>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div>🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Anexos</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel name="Principal">
                    <FormInput
                        document="/document/form81"
                        perms={'81'}
                        title={System.getMenuTitle(81)}
                        validationSchema={yup.object().shape({
                            conteudo: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel name="Anexos">
                    <GridAnexos url="/attachment/form81" perms="81" />
                </TabPanel>
            </TabsInput>
        </div>
    );
};
export const Form812 = () => {
    const System = useSystemContext();
    return (
        <div>
            <FormInput
                document="/document/form812"
                perms={'812'}
                title={System.getMenuTitle(812)}
                validationSchema={yup.object().shape({
                    conteudo: yup.string(),
                })}
                onRenderFields={() => {
                    return (
                        <div className="w-full">
                            <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                        </div>
                    );
                }}
            />
        </div>
    );
};
export const Form813 = () => {
    const System = useSystemContext();
    return (
        <div>
            <FormInput
                document="/document/form813"
                perms={'813'}
                title={System.getMenuTitle(813)}
                validationSchema={yup.object().shape({
                    conteudo: yup.string(),
                })}
                onRenderFields={() => {
                    return (
                        <div className="w-full">
                            <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                        </div>
                    );
                }}
            />
        </div>
    );
};
export const Form814 = () => {
    const System = useSystemContext();
    return (
        <div>
            <FormInput
                document="/document/form814"
                perms={'814'}
                title={System.getMenuTitle(814)}
                validationSchema={yup.object().shape({
                    conteudo: yup.string(),
                })}
                onRenderFields={() => {
                    return (
                        <div className="w-full">
                            <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                        </div>
                    );
                }}
            />
        </div>
    );
};
export const Form82 = () => {
    const System = useSystemContext();
    return (
        <div>
            <FormInput
                document="/document/form82"
                perms={'82'}
                title={System.getMenuTitle(82)}
                initialValues={{
                    conteudo: '',
                }}
                validationSchema={yup.object().shape({
                    conteudo: yup.string(),
                })}
                onRenderFields={() => {
                    return (
                        <div className="w-full">
                            <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                        </div>
                    );
                }}
            />
        </div>
    );
};
export const Form82SGI = () => {
    const System = useSystemContext();
    return (
        <div className="mx-2">
            <TabsInput>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div>🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Gestão</span>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Anexos</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel name="Principal">
                    <FormInput
                        document="/document/form82"
                        perms={'82'}
                        title={System.getMenuTitle(82)}
                        initialValues={{
                            conteudo: '',
                        }}
                        validationSchema={yup.object().shape({
                            conteudo: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel name="Preparação e Resposta de Emergência">
                    <div className="w-full h-80">
                        <Grid
                            url={'/griddocument/grid82'}
                            perms={'82001'}
                            clearAutoEdit={true}
                            GridHeaders={[
                                { title: 'Tipo', field: 'tipo', sort: true, className: 'w-1/6 text-left pl-1 border-l border-gray-300' },
                                { title: 'Data', field: 'data', sort: true, className: 'w-1/6 pl-1 text-left border-l border-gray-300' },
                                { title: 'Local', field: 'local', sort: true, className: 'w-2/6 pl-1 text-left border-l border-gray-300' },
                                { title: 'Responsável', field: 'responsavel', sort: false, className: 'w-2/6 pl-1 text-left border-l border-gray-300' },
                            ]}
                            LineDataPrint={(data) => {
                                return [data.tipo, dateToString(data.data), data.local, data.crew?.nome];
                            }}
                            onRenderForm={(GridModes, SetFieldValue, values) => {
                                return (
                                    <div>
                                        <div className="inline-flex w-full h-8 py-1 pl-2 justify-left bg-amarelo-eq">
                                            <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                                        </div>

                                        <GroupInput>
                                            <SelectInput name="tipo" label="Tipo" className="w-full" listOptions={[{ value: 'Simulado' }, { value: 'Ocorrência' }]} />
                                            <DateInput name="data" label="Data" />
                                            <TimeInput type="time" name="inicio" label="Horário Inicio" />
                                            <TimeInput type="time" name="final" label="Horário Fim" />
                                        </GroupInput>
                                        <Input name="local" label="Local" />
                                        <TextInput name="participantes" label="Participantes" className="w-full" rows="8" />
                                        <TextInput name="cenario" label="Cenário (Descrição do Ocorrido)" className="w-full" rows="8" />
                                        <TextInput name="positivos" label="Pontos Positivos" className="w-full" rows="8" />
                                        <TextInput name="melhoria" label="Oportunidades de Melhoria" className="w-full" rows="8" />
                                        <DBSelectInput name="responsavel" label="Responsável" url={GridModes.insert_mode ? '/crewactive' : '/crew'} displaySelectField="nome" />
                                    </div>
                                );
                            }}
                            validationSchema={yup.object().shape({
                                tipo: yup.string(),
                                data: yup.date(),
                                local: yup.string(),
                                participantes: yup.string(),
                                cenario: yup.string(),
                                positivos: yup.string(),
                                melhoria: yup.string(),
                            })}
                            FormTabs={[
                                () => {
                                    return <div>Home</div>;
                                },
                                () => {
                                    return <div>5W2H</div>;
                                },
                                () => {
                                    return <div>Diário de Bordo</div>;
                                },
                                () => {
                                    return <div>Anexos</div>;
                                },
                            ]}
                            onRenderFormExtra={[
                                (GridModes, item, GridRefresh) => {
                                    return (
                                        <div className={'m-2'}>
                                            <Grid5W2H url={`/method5w2h/form82001-${item._id}`} perms="82001" />
                                        </div>
                                    );
                                },
                                (GridModes, item, GridRefresh) => {
                                    return (
                                        <div>
                                            {!GridModes.insert_mode && (
                                                <div className={'m-2'}>
                                                    <GridDiario url={'/griddocument/grid82-' + item._id} perms={'82001'} />
                                                </div>
                                            )}
                                        </div>
                                    );
                                },
                                (GridModes, item, GridRefresh) => {
                                    // Anexos
                                    return (
                                        <div className={'m-2'}>
                                            <GridAnexos url={`/attachment/form82001-${item._id}`} disableModal={true} perms="82001" />
                                        </div>
                                    );
                                },
                            ]}
                            onBeforeDelete={async (item) => {
                                await System.apiDelete('/griddocument/grid82001-' + item._id);
                                await System.apiDelete(`/attachment/form82001-${item._id}`);
                            }}
                        />
                    </div>
                </TabPanel>
                <TabPanel>
                    <GridAnexos url="/attachment/form82001" perms="82001" />
                </TabPanel>
            </TabsInput>
        </div>
    );
};
export const Form821 = () => {
    const System = useSystemContext();
    return (
        <div className="mx-2">
            <TabsInput>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div>🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Anexos</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel name="Principal">
                    <FormInput
                        document="/document/form821"
                        perms={'821'}
                        title={System.getMenuTitle(821)}
                        initialValues={{
                            conteudo: '',
                        }}
                        validationSchema={yup.object().shape({
                            conteudo: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel>
                    <GridAnexos url="/attachment/form821" perms="821" />
                </TabPanel>
            </TabsInput>
        </div>
    );
};
export const Form822 = () => {
    const System = useSystemContext();
    return (
        <div>
            <FormInput
                document="/document/form822"
                perms={'822'}
                title={System.getMenuTitle(822)}
                initialValues={{
                    conteudo: '',
                }}
                validationSchema={yup.object().shape({
                    conteudo: yup.string(),
                })}
                onRenderFields={() => {
                    return (
                        <div className="w-full">
                            <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                        </div>
                    );
                }}
            />
        </div>
    );
};
export const Form823 = () => {
    const System = useSystemContext();
    return (
        <div className="mx-2">
            <TabsInput>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div>🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Anexos</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel name="Principal">
                    <FormInput
                        document="/document/form823"
                        perms={'823'}
                        title={System.getMenuTitle(823)}
                        initialValues={{
                            conteudo: '',
                        }}
                        validationSchema={yup.object().shape({
                            conteudo: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel>
                    <GridAnexos url="/attachment/form823" perms="823" />
                </TabPanel>
            </TabsInput>
        </div>
    );
};
export const Form824 = () => {
    const System = useSystemContext();
    return (
        <div className="mx-2">
            <TabsInput>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div>🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Anexos</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel name="Principal">
                    <FormInput
                        document="/document/form824"
                        perms={'824'}
                        title={System.getMenuTitle(824)}
                        initialValues={{
                            conteudo: '',
                        }}
                        validationSchema={yup.object().shape({
                            conteudo: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel name="Anexos">
                    <GridAnexos url={'/attachment/form824'} perms="824" />
                </TabPanel>
            </TabsInput>
        </div>
    );
};
export const Form83 = () => {
    const System = useSystemContext();
    return (
        <div className="mx-2">
            <TabsInput>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div>🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Gestão</span>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Anexos</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel name="Principal">
                    <FormInput
                        document="/document/form83"
                        perms={'83'}
                        title={System.getMenuTitle(83)}
                        initialValues={{
                            conteudo: '',
                        }}
                        validationSchema={yup.object().shape({
                            conteudo: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel name="Gestão">
                    <Grid
                        url={'/griddocument/grid83'}
                        perms={'83'}
                        clearAutoEdit={true}
                        GridHeaders={[
                            { title: 'Código', field: 'codigo', sort: true, className: 'w-1/6 text-left pl-1' },
                            { title: 'Título', field: 'titulo', sort: true, className: 'w-2/6 pl-1 text-left border-l border-gray-300' },
                            { title: 'De', field: 'de', sort: true, className: 'w-1/6 pl-1 text-left border-l border-gray-300' },
                            { title: 'Até', field: 'ate', sort: true, className: 'w-1/6 pl-1 text-left border-l border-gray-300' },
                            { title: 'Responsável', field: 'responsavel', sort: true, className: 'w-1/6 pl-1 text-left border-l border-gray-300' },
                        ]}
                        LineDataPrint={(data) => {
                            return [data.codigo, data.titulo, dateToString(data.de), dateToString(data.ate), data.crew?.nome];
                        }}
                        onPrepareSaveRecord={(values) => {
                            let newForm = new FormData();
                            for (let key in values) {
                                newForm.append(key, values[key]);
                            }
                            return newForm;
                        }}
                        onRenderForm={(GridModes, setFieldValue, values) => {
                            return (
                                <div>
                                    <div className="inline-flex w-full h-8 py-1 pl-2 justify-left bg-amarelo-eq">
                                        <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                                    </div>
                                    <GroupInput>
                                        <Input name="codigo" label="Código" className={'w-1/4'} />
                                        <Input name="titulo" label="Título" />
                                    </GroupInput>
                                    <GroupInput>
                                        <DateInput name="de" label="Período de" />
                                        <DateInput name="ate" label="Até" />
                                    </GroupInput>
                                    <GroupInput>
                                        <DBSelectInput name="responsavel" label="Responsável" url={GridModes.insert_mode ? '/crewactive' : '/crew'} displaySelectField="nome" />
                                    </GroupInput>
                                    <TextInput name="descricao" label="Descrição" className="w-full" rows="4" />
                                </div>
                            );
                        }}
                        validationSchema={yup.object().shape({
                            codigo: yup.string(),
                            titulo: yup.string(),
                            de: yup.date(),
                            ate: yup.date(),
                            responsavel: yup.string(),
                        })}
                        FormTabs={[
                            () => {
                                return <div>Home</div>;
                            },
                            () => {
                                return <div>Entrada</div>;
                            },
                            () => {
                                return <div>Saída</div>;
                            },
                            () => {
                                return <div>Análise Crítica</div>;
                            },
                            () => {
                                return <div>Verificação</div>;
                            },
                            () => {
                                return <div>Validação</div>;
                            },
                            () => {
                                return <div>Controle de Alterações</div>;
                            },
                            () => {
                                return (
                                    <div className="inline-flex justify-center">
                                        <span>Anexos</span>
                                    </div>
                                );
                            },
                        ]}
                        onRenderFormExtra={[
                            (GridModes, item, GridRefresh) => {
                                // Entrada
                                return (
                                    <div>
                                        <FormInput
                                            document={`/griddocument/grid83/${item._id}`}
                                            perms={'83'}
                                            onRenderFields={(setFieldValue, values) => {
                                                return (
                                                    <div className="w-full">
                                                        <GroupInput>
                                                            <DateInput name="entrada_inicio" label="Data Início" className={'inline-block'} />
                                                            <DateInput name="entrada_fim" label="Data Final" className={'inline-block'} />
                                                            <DBSelectInput
                                                                name="entrada_responsavel"
                                                                label="Responsável"
                                                                url={GridModes.insert_mode ? '/crewactive' : '/crew'}
                                                                displaySelectField="nome"
                                                            />
                                                        </GroupInput>
                                                        <GroupInput>
                                                            <SelectInput
                                                                name="entrada_status"
                                                                label="Status"
                                                                listOptions={[{ value: 'Pendente' }, { value: 'Concluído' }]}
                                                                className={'w-44'}
                                                            />
                                                        </GroupInput>
                                                    </div>
                                                );
                                            }}
                                            onPrepareSaveRecord={(values, sender) => {
                                                // requerido para anexos
                                                let newForm = new FormData();
                                                for (let key in values) {
                                                    newForm.append(key, values[key]);
                                                }
                                                return newForm;
                                            }}
                                            validationSchema={yup.object().shape({
                                                entrada_inicio: yup.date(),
                                                entrada_fim: yup.date(),
                                                entrada_responsavel: yup.string(),
                                                entrada_anexo: yup.string(),
                                                entrada_status: yup.string(),
                                            })}
                                        />
                                        <div className={'m-2'}>
                                            <Grid
                                                url={`/griddocument/grid83-entrada-${item._id}`}
                                                perms={'83'}
                                                disableModal={true}
                                                GridHeaders={[
                                                    { title: 'Requisitos', field: 'requisitos', sort: true, className: 'w-1/2 text-left pl-1' },
                                                    { title: 'Análise Crítica', field: 'analise', sort: true, className: 'w-1/2 pl-1 text-left border-l border-gray-300' },
                                                ]}
                                                LineDataPrint={(data) => {
                                                    return [data.requisitos, data.analise];
                                                }}
                                                onRenderForm={() => {
                                                    return (
                                                        <div className="w-full">
                                                            <Input name="requisitos" label="Requisitos" />
                                                            <Input name="analise" label="Análise Crítica" />
                                                        </div>
                                                    );
                                                }}
                                                validationSchema={() => {
                                                    return yup.object().shape({
                                                        requisitos: yup.string(),
                                                        analise: yup.string(),
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>
                                );
                            },
                            (GridModes, item, GridRefresh) => {
                                // Saida
                                return (
                                    <div>
                                        <FormInput
                                            document={`/griddocument/grid83/${item._id}`}
                                            perms={'83'}
                                            onRenderFields={() => {
                                                return (
                                                    <div className="w-full">
                                                        <GroupInput>
                                                            <DateInput name="saida_inicio" label="Data Início" className={'inline-block'} />
                                                            <DateInput name="saida_fim" label="Data Final" className={'inline-block'} />
                                                            <DBSelectInput
                                                                name="saida_responsavel"
                                                                label="Responsável"
                                                                url={GridModes.insert_mode ? '/crewactive' : '/crew'}
                                                                displaySelectField="nome"
                                                            />
                                                        </GroupInput>
                                                        <SelectInput
                                                            name="saida_status"
                                                            label="Status"
                                                            listOptions={[{ value: 'Pendente' }, { value: 'Concluído' }]}
                                                            className={'w-44'}
                                                        />
                                                    </div>
                                                );
                                            }}
                                            validationSchema={yup.object().shape({
                                                saida_inicio: yup.date(),
                                                saida_fim: yup.date(),
                                                saida_responsavel: yup.string(),
                                                saida_status: yup.string(),
                                            })}
                                        />
                                        <div className={'m-2'}>
                                            <Grid
                                                url={`/griddocument/grid83-saida-${item._id}`}
                                                perms={'83'}
                                                disableModal={true}
                                                GridHeaders={[
                                                    { title: 'Requisitos', field: 'requisitos', sort: true, className: 'w-1/2 text-left pl-1' },
                                                    { title: 'Análise Crítica', field: 'analise', sort: true, className: 'w-1/2 pl-1 text-left border-l border-gray-300' },
                                                ]}
                                                LineDataPrint={(data) => {
                                                    return [data.requisitos, data.analise];
                                                }}
                                                onRenderForm={() => {
                                                    return (
                                                        <div className="w-full">
                                                            <Input name="requisitos" label="Requisitos" />
                                                            <Input name="analise" label="Análise Crítica" />
                                                        </div>
                                                    );
                                                }}
                                                validationSchema={() => {
                                                    return yup.object().shape({
                                                        requisitos: yup.string(),
                                                        analise: yup.string(),
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>
                                );
                            },
                            (GridModes, item, GridRefresh) => {
                                // Análise Crítica
                                var url = `/griddocument/grid83-analise-${item._id}`;
                                return (
                                    <div className={'m-2'}>
                                        <Grid
                                            url={url}
                                            perms={'83'}
                                            disableModal={true}
                                            GridHeaders={[
                                                { title: 'Inicio', field: 'inicio', sort: true, className: 'w-1/12 text-left pl-1' },
                                                { title: 'Fim', field: 'fim', sort: true, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                                                { title: 'Titulo', field: 'titulo', sort: true, className: 'w-7/12 pl-1 text-left border-l border-gray-300' },
                                                { title: 'Status', field: 'status', sort: true, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                                                { title: 'Responsável', field: 'reponsavel', sort: false, className: 'w-2/12 pl-1 text-left border-l border-gray-300' },
                                            ]}
                                            LineDataPrint={(data) => {
                                                return [dateToString(data.inicio), dateToString(data.fim), data.titulo, data.status, data.crew?.nome];
                                            }}
                                            onRenderForm={() => {
                                                return (
                                                    <div className="w-full">
                                                        <GroupInput>
                                                            <DateInput name="inicio" label="Início" />
                                                            <DateInput name="fim" label="Fim" />
                                                            <Input name="titulo" label="Titulo" />
                                                        </GroupInput>
                                                        <TextInput name="descricao" label="Descrição" />
                                                        <GroupInput>
                                                            <SelectInput
                                                                name="status"
                                                                label="Status"
                                                                className={'w-36'}
                                                                listOptions={[{ value: 'Pendente' }, { value: 'Concluído' }]}
                                                            />
                                                            <DBSelectInput
                                                                name="responsavel"
                                                                label="Responsável"
                                                                url={GridModes.insert_mode ? '/crewactive' : '/crew'}
                                                                displaySelectField="nome"
                                                            />
                                                        </GroupInput>
                                                    </div>
                                                );
                                            }}
                                            validationSchema={() => {
                                                return yup.object().shape({
                                                    requisitos: yup.string(),
                                                    analize: yup.string(),
                                                    status: yup.string(),
                                                });
                                            }}
                                            FormTabs={[
                                                () => {
                                                    return <div>Home</div>;
                                                },
                                                () => {
                                                    return <div>Presença</div>;
                                                },
                                                () => {
                                                    return <div>Anexo</div>;
                                                },
                                            ]}
                                            onRenderFormExtra={[
                                                (GridModes, subitem, GridRefresh) => {
                                                    // presença
                                                    return (
                                                        <Grid
                                                            url={`/griddocument/grid83-analise-presenca-${subitem._id}`}
                                                            perms={'83'}
                                                            disableModal={true}
                                                            GridHeaders={[
                                                                { title: 'Nome', field: 'nome', sort: true, className: 'w-1/3 text-left pl-1' },
                                                                { title: 'Presença', field: 'presenca', sort: true, className: 'w-1/5 pl-1 text-left border-l border-gray-300' },
                                                                {
                                                                    title: 'Justificativa',
                                                                    field: 'justificativa',
                                                                    sort: true,
                                                                    className: 'w-1/2 pl-1 text-left border-l border-gray-300',
                                                                },
                                                            ]}
                                                            LineDataPrint={(data) => {
                                                                return [data.nome, data.presenca ? 'Sim' : 'Não', data.justificativa];
                                                            }}
                                                            onRenderForm={() => {
                                                                return (
                                                                    <div className="w-full">
                                                                        <GroupInput>
                                                                            <Input name="nome" label="Nome" />
                                                                            <CheckBoxInput name="presenca" label="Presença" />
                                                                        </GroupInput>
                                                                        <Input name="justificativa" label="Justificativa" />
                                                                    </div>
                                                                );
                                                            }}
                                                            validationSchema={() => {
                                                                return yup.object().shape({
                                                                    nome: yup.string(),
                                                                    presenca: yup.boolean(),
                                                                    justificativa: yup.string(),
                                                                });
                                                            }}
                                                        />
                                                    );
                                                },
                                                (GridModes, subitem, GridRefresh) => {
                                                    // anexo
                                                    return (
                                                        <FormInput
                                                            document={`${url}/${subitem._id}`}
                                                            perms={'83'}
                                                            onPrepareSaveRecord={(values, sender) => {
                                                                let newForm = new FormData();
                                                                for (let key in values) {
                                                                    newForm.append(key, values[key]);
                                                                }
                                                                return newForm;
                                                            }}
                                                            validationSchema={yup.object().shape({
                                                                arquivo: yup.string(),
                                                            })}
                                                            onAfterSave={() => {
                                                                GridRefresh();
                                                            }}
                                                            onRenderFields={(setFieldValue, values, callCommit) => {
                                                                return <AttachmentInput name="attachment" perms={'83'} label="Anexo" callParentCommit={callCommit} />;
                                                            }}
                                                        />
                                                    );
                                                },
                                            ]}
                                        />
                                    </div>
                                );
                            },
                            (GridModes, item, GridRefresh) => {
                                // Verificação
                                return (
                                    <FormInput
                                        document={`/griddocument/grid83/${item._id}`}
                                        perms={'83'}
                                        onRenderFields={() => {
                                            return (
                                                <div className="w-full">
                                                    <GroupInput>
                                                        <DateInput name="verificacao_inicio" label="Data Início" className={'inline-block'} />
                                                        <DateInput name="verificacao_fim" label="Data Final" className={'inline-block'} />
                                                        <DBSelectInput
                                                            name="verificacao_responsavel"
                                                            label="Responsável"
                                                            url={GridModes.insert_mode ? '/crewactive' : '/crew'}
                                                            displaySelectField="nome"
                                                        />
                                                    </GroupInput>
                                                    <TextInput name="verificacao_descricao" label="Descrição" />
                                                    <SelectInput
                                                        name="verificacao_status"
                                                        label="Status"
                                                        listOptions={[{ value: 'Pendente' }, { value: 'Concluído' }]}
                                                        className={'inline-block'}
                                                    />
                                                </div>
                                            );
                                        }}
                                        validationSchema={yup.object().shape({
                                            verificacao_inicio: yup.date(),
                                            verificacao_fim: yup.date(),
                                            verificacao_responsavel: yup.string(),
                                            verificacao_status: yup.string(),
                                        })}
                                    />
                                );
                            },
                            (GridModes, item, GridRefresh) => {
                                // Validação
                                return (
                                    <FormInput
                                        document={`/griddocument/grid83/${item._id}`}
                                        perms={'83'}
                                        onRenderFields={() => {
                                            return (
                                                <div className="w-full">
                                                    <GroupInput>
                                                        <DateInput name="validacao_inicio" label="Data Início" className={'inline-block'} />
                                                        <DateInput name="validacao_fim" label="Data Final" className={'inline-block'} />
                                                        <DBSelectInput
                                                            name="validacao_responsavel"
                                                            label="Responsável"
                                                            url={GridModes.insert_mode ? '/crewactive' : '/crew'}
                                                            displaySelectField="nome"
                                                        />
                                                    </GroupInput>
                                                    <TextInput name="validacao_descricao" label="Descrição" />
                                                    <SelectInput
                                                        name="validacao_status"
                                                        label="Status"
                                                        listOptions={[{ value: 'Pendente' }, { value: 'Concluído' }]}
                                                        className={'inline-block'}
                                                    />
                                                </div>
                                            );
                                        }}
                                        validationSchema={yup.object().shape({
                                            validacao_inicio: yup.date(),
                                            validacao_fim: yup.date(),
                                            validacao_responsavel: yup.string(),
                                            validacao_status: yup.string(),
                                        })}
                                    />
                                );
                            },
                            (GridModes, item, GridRefresh) => {
                                // Controle de Alterações
                                return (
                                    <div className={'m-2'}>
                                        <Grid
                                            url={`/griddocument/grid83-alteracao-${item._id}`}
                                            perms={'83'}
                                            disableModal={true}
                                            GridHeaders={[
                                                { title: 'Responsável', field: 'responsavel', sort: true, className: 'w-2/12 text-left pl-1' },
                                                { title: 'Descrição', field: 'descricao', sort: true, className: 'w-5/12 pl-1 text-left border-l border-gray-300' },
                                                { title: 'Observação', field: 'observacao', sort: true, className: 'w-4/12 pl-1 text-left border-l border-gray-300' },
                                                { title: 'Status', field: 'status', sort: true, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                                            ]}
                                            LineDataPrint={(data) => {
                                                return [data.crew?.nome, data.descricao, data.observacao, data.status];
                                            }}
                                            onRenderForm={() => {
                                                return (
                                                    <div className="w-full">
                                                        <GroupInput>
                                                            <DateInput name="inicio" label="Data Início" className={'inline-block'} />
                                                            <DateInput name="fim" label="Data Final" className={'inline-block'} />
                                                            <DBSelectInput
                                                                name="responsavel"
                                                                label="Responsável"
                                                                url={GridModes.insert_mode ? '/crewactive' : '/crew'}
                                                                displaySelectField="nome"
                                                            />
                                                        </GroupInput>
                                                        <TextInput name="descricao" label="Descrição" />
                                                        <TextInput name="observacao" label="Observação" />
                                                        <SelectInput
                                                            name="status"
                                                            label="Status"
                                                            className={'inline-block'}
                                                            listOptions={[{ value: 'Pendente' }, { value: 'Concluído' }]}
                                                        />
                                                    </div>
                                                );
                                            }}
                                            validationSchema={() => {
                                                return yup.object().shape({
                                                    responsavel: yup.string(),
                                                    descricao: yup.string(),
                                                    observacao: yup.string(),
                                                    status: yup.string(),
                                                });
                                            }}
                                        />
                                    </div>
                                );
                            },
                            (GridModes, item, GridRefresh) => {
                                // Anexo
                                return <GridAnexos url={`/attachment/form83-${item._id}`} disableModal={true} perms="83" />;
                            },
                        ]}
                        onBeforeDelete={async (item) => {
                            await System.apiDelete('/griddocument/grid83-entrada-' + item._id);
                            await System.apiDelete('/griddocument/grid83-saida-' + item._id);
                            await System.apiDelete('/griddocument/grid83-analise-' + item._id);
                            await System.apiDelete('/griddocument/grid83-analise-presenca-' + item._id);
                            await System.apiDelete('/griddocument/grid83-alteracao-' + item._id);
                            await System.apiDelete(`/attachment/form83-${item._id}`);
                        }}
                    />
                </TabPanel>
                <TabPanel name="Anexos">
                    <GridAnexos url="/attachment/form83" perms="83" />
                </TabPanel>
            </TabsInput>
        </div>
    );
};
export const Form84 = () => {
    const System = useSystemContext();
    const [tipos, setTipos] = useState([]);
    const [categorias, setCategorias] = useState([]);

    var criterios = [];
    function getData() {
        System.apiGet('/griddocument/grid84-servicos').then((response) => {
            setTipos(response.data);
        });
        System.apiGet('/griddocument/grid84-categorias').then((response) => {
            setCategorias(response.data);
        });
    }

    useEffect(() => {
        getData();
        // eslint-disable-next-line
    }, []);

    return (
        <div className="mx-2">
            <TabsInput>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div>🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Gestão</span>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Anexos</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel name="Principal">
                    <FormInput
                        document="/document/form84"
                        perms={'84'}
                        title={System.getMenuTitle(84)}
                        initialValues={{
                            conteudo: '',
                        }}
                        validationSchema={yup.object().shape({
                            conteudo: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel name="Gestão">
                    <Grid
                        url={'/griddocument/grid84'}
                        perms={'84'}
                        disableModal={true}
                        clearAutoEdit={true}
                        showPDFExport={true}
                        showCSVExport={true}
                        GridHeaders={[
                            { title: 'Provedor Externo', field: 'provedor', sort: true, className: 'w-6/12 text-left pl-1' },
                            { title: 'Tipo', field: 'tipo', sort: true, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                            { title: 'Categoria', field: 'categoria', sort: true, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                            { title: 'Nota', field: 'lastNota', sort: true, className: 'w-1/12 pl-1 text-center border-l border-gray-300' },
                            { title: 'Responsável', field: 'responsavel', sort: true, className: 'w-2/12 pl-1 text-left border-l border-gray-300' },
                            { title: 'Reavaliação', field: 'reavaliacao', sort: true, className: 'w-1/12 pl-1 text-left border-l border-gray-300' },
                        ]}
                        onAfterLoadData={(received) => {
                            let result = received.map((data) => {
                                let revisao = data.revisao.sort((a, b) => a.data.localeCompare(b.data));
                                data.lastNota = revisao[revisao.length - 1];
                                return data;
                            });
                            return result;
                        }}
                        LineDataPrint={(data) => {
                            return [
                                data.provedor,
                                typeof tipos === 'object' ? tipos.find((item) => item.id === data.tipo)?.tipo : '',
                                typeof categorias === 'object' ? categorias.find((item) => item.id === data.categoria)?.titulo : '',
                                data.lastNota ? parseFloat(data.lastNota.notacalculada).toFixed(1) : '',
                                data.crew?.nome,
                                dateToString(data.reavaliacao),
                            ];
                        }}
                        validationSchema={yup.object().shape({
                            provedor: yup.string(),
                            tipo: yup.string(),
                            reavaliacao: yup.date(),
                        })}
                        onRenderForm={(GridModes, setFieldValue, values) => {
                            return (
                                <div>
                                    <div className="inline-flex w-full h-8 py-1 pl-2 justify-left bg-amarelo-eq">
                                        <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                                    </div>
                                    <GroupInput>
                                        <Input name="codigo" label="Código" className={'w-1/4'} />
                                        <Input name="provedor" label="Provedor Externo" />
                                    </GroupInput>
                                    <GroupInput>
                                        <DateInput name="data" label="Data Cadastro / Atualização" information="Apartir desta data a nota do fornecedor volta a ser 10" />
                                        <DateInput name="reavaliacao" label="Data Reavaliação" />
                                    </GroupInput>
                                    <CepInput
                                        name="cep"
                                        label="CEP"
                                        className="w-44"
                                        onReceivedCep={(received) => {
                                            setFieldValue('endereco', received.logradouro);
                                            setFieldValue('bairro', received.bairro);
                                            setFieldValue('cidade', received.localidade);
                                            setFieldValue('estado', received.uf);
                                            setFieldValue('pais', 'Brasil');
                                        }}
                                    />
                                    <GroupInput>
                                        <Input name="endereco" label="Endereço" />
                                        <Input name="numero" label="Número" className="w-32" />
                                    </GroupInput>
                                    <GroupInput>
                                        <Input name="bairro" label="Bairro" />
                                        <Input name="cidade" label="Cidade" />
                                        <MaskInput
                                            name="estado"
                                            label="Estado"
                                            className="w-48"
                                            mask="aa"
                                            beforeMaskedValueChange={function (newState) {
                                                newState.value = newState.value.toUpperCase();
                                                return newState;
                                            }}
                                        />
                                        <Input name="pais" label="País" />
                                    </GroupInput>
                                    <GroupInput>
                                        <Input name="contato_1_nome" label="Contato 1" />
                                        <Input name="contato_1_email" label="Email" />
                                        <MaskInput name="contato_1_fone" label="Telefone" mask="(99) 9 9999-9999" />
                                    </GroupInput>
                                    <GroupInput>
                                        <Input name="contato_2_nome" label="Contato 2" />
                                        <Input name="contato_2_email" label="Email" />
                                        <MaskInput name="contato_2_fone" label="Telefone" mask="(99) 9 9999-9999" />
                                    </GroupInput>
                                    <GroupInput>
                                        <DBSelectInput name="tipo" label="Tipo" displaySelectField="tipo" url="/griddocument/grid84-servicos" />
                                        <SelectInput
                                            name="periodicidade"
                                            label="Periodicidade de Controle"
                                            listOptions={[
                                                { value: 'Mensal' },
                                                { value: 'Bimestral' },
                                                { value: 'Trimestral' },
                                                { value: 'Quatrimestral' },
                                                { value: 'Semestral' },
                                                { value: 'Anual' },
                                                { value: 'Bienal' },
                                            ]}
                                        />
                                    </GroupInput>
                                    <GroupInput>
                                        <DBSelectInput name="categoria" label="Categoria" displaySelectField="titulo" url="/griddocument/grid84-categorias" />
                                        <DBSelectInput name="responsavel" label="Responsável" url={GridModes.insert_mode ? '/crewactive' : '/crew'} displaySelectField="nome" />
                                    </GroupInput>
                                </div>
                            );
                        }}
                        FormTabs={[
                            () => {
                                return <div>Home</div>;
                            },
                            () => {
                                return <div>Inspeção</div>;
                            },
                            () => {
                                return <div>Gráfico</div>;
                            },
                            () => {
                                return <div>Anexos</div>;
                            },
                            () => {
                                return <div>Diário de Bordo</div>;
                            },
                        ]}
                        onSelectItem={async (item) => {
                            await System.apiGet(`/griddocument/grid84-${item.tipo}-criterios`).then((response) => {
                                criterios = response.data;
                            });
                        }}
                        onRenderFormExtra={[
                            (GridModes, item, GridRefresh) => {
                                // Inspeção
                                var url = `/griddocument/grid84-ipde-${item._id}`;
                                function saveNotaFinal(item) {
                                    System.apiPut(`${url}/${item._id}`, item);
                                    return true;
                                }
                                return (
                                    <div className={'m-2'}>
                                        <Grid
                                            url={url}
                                            perms={'84'}
                                            defaultSortField={'data'}
                                            disableModal={true}
                                            GridHeaders={[
                                                { title: 'Data', field: 'data', sort: false, className: 'w-4/12 text-center pl-1' },
                                                { title: 'Nota Fiscal', field: 'notafiscal', sort: false, className: 'w-4/12 pl-1 text-center border-l border-gray-300' },
                                                { title: 'Nota Final', field: 'notacalculada', sort: false, className: 'w-4/12 pl-1 text-center border-l border-gray-300' },
                                            ]}
                                            LineDataPrint={(data) => {
                                                return [dateToString(data.data), data.notafiscal, parseFloat(data.notacalculada).toFixed(1)];
                                            }}
                                            forceReloadAfterSave={true}
                                            onAfterLoadData={(oldReceived) => {
                                                // converte as chaves e valores para utf-8 evitando erro de acentos
                                                let received = [];
                                                let dt_atualizacao = new Date(item.data + ' 00:00:00') || new Date();
                                                let resetNota = isNaN(dt_atualizacao.getTime()) ? 0 : 1;

                                                oldReceived.forEach((item) => {
                                                    let tmp = {};
                                                    Object.entries(item).forEach(([key, value]) => {
                                                        const normalizedKey = normalizeText(key);
                                                        const normalizedValue = value; //normalizeText(value);
                                                        tmp[normalizedKey] = normalizedValue;
                                                    });
                                                    received.push(tmp);
                                                });
                                                // ---- [ final ]----------------
                                                const dtIni = new Date('2021-01-01');
                                                let foraPeriodo = 0;
                                                var notaAnterior = 10;
                                                let notacalculada = 0;
                                                let newData = received.map((item) => {
                                                    let dt = new Date(item.data + ' 00:00:00');
                                                    if (foraPeriodo === 0) {
                                                        if (dt >= dtIni) {
                                                            foraPeriodo = 1;
                                                            notaAnterior = 10;
                                                        }
                                                    }
                                                    if (resetNota === 1 && dt >= dt_atualizacao) {
                                                        resetNota = 0;
                                                        notaAnterior = 10;
                                                    }
                                                    notacalculada = notaAnterior;
                                                    criterios.forEach((criterio) => {
                                                        let criterioNome = normalizeText(criterio.criterio);
                                                        if (item[criterioNome] !== 'Atende') {
                                                            notacalculada -= criterio.demerito;
                                                        }
                                                    });
                                                    notaAnterior = notacalculada;
                                                    item.notacalculada = parseFloat(notacalculada);
                                                    saveNotaFinal(item);
                                                    return item;
                                                });
                                                return newData;
                                            }}
                                            onPrepareSaveRecord={(values) => {
                                                values.gridparent = item._id;
                                                let newForm = new FormData();
                                                for (let key in values) {
                                                    newForm.append(key, values[key]);
                                                }
                                                return newForm;
                                            }}
                                            onRenderForm={() => {
                                                return (
                                                    <div className="w-full">
                                                        <GroupInput>
                                                            <DateInput name="data" label="Data" />
                                                            <Input name="notafiscal" label="Nota Fiscal" />
                                                        </GroupInput>
                                                        <GroupInput>
                                                            {criterios.map((item, index) => {
                                                                return (
                                                                    <SelectInput
                                                                        key={index}
                                                                        name={normalizeText(item.criterio)}
                                                                        label={item.criterio}
                                                                        listOptions={[{ value: 'Atende' }, { value: 'Não Atende' }]}
                                                                    />
                                                                );
                                                            })}
                                                        </GroupInput>
                                                        <TextInput name="observacoes" label="Observações" rows={2} />
                                                    </div>
                                                );
                                            }}
                                            validationSchema={() => {
                                                return yup.object().shape({
                                                    data: yup.date().required('Data requerida'),
                                                });
                                            }}
                                        />
                                    </div>
                                );
                            },
                            (GridModes, item, GridRefresh) => {
                                // Grafico
                                return <Chart84 item={item} />;
                            },
                            (GridModes, item, GridRefresh) => {
                                // Anexos
                                return (
                                    <div className={'m-2'}>
                                        <GridAnexos url={`/attachment/grid84-${item._id}`} perms="84" />
                                    </div>
                                );
                            },
                            (GridModes, item, GridRefresh) => {
                                // Diário de Bordo
                                return (
                                    <div>
                                        {!GridModes.insert_mode && (
                                            <div className={'m-2'}>
                                                <GridDiario url={'/griddocument/grid84-diario-' + item._id} perms={'84'} />
                                            </div>
                                        )}
                                    </div>
                                );
                            },
                        ]}
                        onBeforeDelete={async (item) => {
                            await System.apiDelete('/griddocument/grid84-ipde-' + item._id);
                            await System.apiDelete('/griddocument/grid84-categorias-' + item._id);
                            await System.apiDelete('/griddocument/grid84-diario-' + item._id);
                            await System.apiDelete('/attachment/grid84-' + item._id);
                        }}
                    />
                </TabPanel>
                <TabPanel>
                    <GridAnexos url={'/attachment/grid84'} perms="84" />
                </TabPanel>
            </TabsInput>
        </div>
    );
};
export const Form841 = () => {
    const System = useSystemContext();
    return (
        <div className="mx-2">
            <TabsInput>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div>🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Anexos</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel name="Principal">
                    <FormInput
                        document="/document/form841"
                        perms="841"
                        title={System.getMenuTitle(841)}
                        initialValues={{
                            conteudo: '',
                        }}
                        validationSchema={yup.object().shape({
                            conteudo: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel name="Anexos">
                    <GridAnexos url={'/attachment/form841'} perms="841" />
                </TabPanel>
            </TabsInput>
        </div>
    );
};
export const Form842 = () => {
    const System = useSystemContext();
    return (
        <div>
            <FormInput
                document="/document/form842"
                perms="842"
                title={System.getMenuTitle(842)}
                initialValues={{
                    conteudo: '',
                }}
                validationSchema={yup.object().shape({
                    conteudo: yup.string(),
                })}
                onRenderFields={() => {
                    return (
                        <div className="w-full">
                            <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                        </div>
                    );
                }}
            />
        </div>
    );
};
export const Form843 = () => {
    const System = useSystemContext();
    return (
        <div>
            <FormInput
                document="/document/form843"
                perms="843"
                title={System.getMenuTitle(843)}
                initialValues={{
                    conteudo: '',
                }}
                validationSchema={yup.object().shape({
                    conteudo: yup.string(),
                })}
                onRenderFields={() => {
                    return (
                        <div className="w-full">
                            <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                        </div>
                    );
                }}
            />
        </div>
    );
};
export const Form851 = () => {
    const System = useSystemContext();
    return (
        <div>
            <TabsInput>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div>🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Anexos</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel name="Principal">
                    <FormInput
                        document="/document/form851"
                        perms="851"
                        title={System.getMenuTitle(851)}
                        initialValues={{
                            conteudo: '',
                        }}
                        validationSchema={yup.object().shape({
                            conteudo: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel name="Anexos">
                    <GridAnexos url="/attachment/form851" perms="851" />
                </TabPanel>
            </TabsInput>
        </div>
    );
};
export const Form852 = () => {
    const System = useSystemContext();
    return (
        <div className="mx-2">
            <TabsInput>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div>🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Gestão I</span>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Gestão R</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel name="Principal">
                    <FormInput
                        document="/document/form852"
                        perms="852"
                        title={System.getMenuTitle(852)}
                        initialValues={{
                            conteudo: '',
                        }}
                        validationSchema={yup.object().shape({
                            conteudo: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel name="Identificação">
                    <Grid
                        url={'/griddocument/grid852_identificacao'}
                        perms={'852'}
                        GridHeaders={[
                            { title: 'Descrição', field: 'descricao', sort: true, className: 'w-5/6 text-left pl-1' },
                            { title: 'Etapa', field: 'etapa', sort: true, className: 'w-1/6 text-left border-l border-gray-300' },
                        ]}
                        LineDataPrint={(data) => {
                            return [data.descricao, data.etapa];
                        }}
                        onRenderForm={(GridModes, SetFieldValue, values) => {
                            return (
                                <div>
                                    <div className="inline-flex w-full h-8 py-1 pl-2 justify-left bg-amarelo-eq">
                                        <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                                    </div>
                                    <Input name="descricao" label="Descrição" />
                                    <Input name="etapa" label="Etapa" />
                                    <Input name="situacao" label="Situação" />
                                    <Input name="controle" label="Controle" />
                                </div>
                            );
                        }}
                        validationSchema={yup.object().shape({
                            descricao: yup.string().required(),
                            etapa: yup.string(),
                            situacao: yup.string(),
                            controle: yup.string(),
                        })}
                    />
                </TabPanel>
                <TabPanel name="Rastreabilidade">
                    <Grid
                        url={'/griddocument/grid852_rastreabilidade'}
                        perms={'852'}
                        GridHeaders={[
                            { title: 'Descrição', field: 'descricao', sort: true, className: 'w-5/6 text-left pl-1' },
                            { title: 'Etapa', field: 'etapa', sort: true, className: 'w-1/6 pl-1 text-left border-l border-gray-300' },
                        ]}
                        LineDataPrint={(data) => {
                            return [data.descricao, data.etapa];
                        }}
                        onRenderForm={(GridModes, SetFieldValue, values) => {
                            return (
                                <div>
                                    <div className="inline-flex w-full h-8 py-1 pl-2 justify-left bg-amarelo-eq">
                                        <span>{!GridModes.insert_mode ? 'Editarndo' : 'Novo registro'}</span>
                                    </div>
                                    <Input name="descricao" label="Descrição" />
                                    <Input name="etapa" label="Etapa" />
                                    <Input name="situacao" label="Situação" />
                                    <Input name="controle" label="Controle" />
                                </div>
                            );
                        }}
                        validationSchema={yup.object().shape({
                            descricao: yup.string().required(),
                            etapa: yup.string(),
                            situacao: yup.string(),
                            controle: yup.string(),
                        })}
                    />
                </TabPanel>
            </TabsInput>
        </div>
    );
};
export const Form853 = () => {
    const System = useSystemContext();
    return (
        <div className="mx-2">
            <TabsInput>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div>🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Gestão</span>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Anexos</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel name="Principal">
                    <FormInput
                        document="/document/form853"
                        perms="853"
                        title={System.getMenuTitle(853)}
                        initialValues={{
                            conteudo: '',
                        }}
                        validationSchema={yup.object().shape({
                            conteudo: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel name="Liberação de P&S">
                    <Grid
                        url={'/griddocument/grid853'}
                        perms={'853'}
                        clearAutoEdit={true}
                        GridTitle={'8.5.3 - Propriedade do cliente ou Provedor Externo'}
                        GridHeaders={[
                            { title: 'Descrição', field: 'descricao', sort: true, className: 'w-1/4 text-left pl-1' },
                            { title: 'Cliente ou Provedor Externo', field: 'provedor', sort: true, className: 'w-1/4 pl-1 text-left border-l border-gray-300' },
                            { title: 'Unidade de Medida', field: 'unidade', sort: true, className: 'w-1/4 pl-1 text-left border-l border-gray-300' },
                            { title: 'Data', field: 'data', sort: true, className: 'w-1/4 pl-1 text-left border-l border-gray-300' },
                        ]}
                        LineDataPrint={(data) => {
                            return [data.descricao, data.provedor, data.unidade, dateToString(data.data)];
                        }}
                        onRenderForm={(GridModes, SetFieldValue, values) => {
                            return (
                                <div>
                                    <div className="inline-flex w-full h-8 py-1 pl-2 justify-left bg-amarelo-eq">
                                        <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                                    </div>
                                    <Input name="descricao" label="Descrição" />
                                    <Input name="provedor" label="Cliente ou Provedor Externo" />
                                    <GroupInput>
                                        <Input name="unidade" label="Unidade de Medida" />
                                        <DateInput name="data" label="Data" />
                                    </GroupInput>
                                </div>
                            );
                        }}
                        FormTabs={[
                            () => {
                                return <div>Home</div>;
                            },
                            () => {
                                return <div>Controle</div>;
                            },
                        ]}
                        onBeforeDelete={async (item) => {
                            await System.apiDelete('/griddocument/grid853-' + item._id);
                        }}
                        onRenderFormExtra={[
                            (GridModes, item, GridRefresh) => {
                                return (
                                    <div>
                                        {!GridModes.insert_mode && (
                                            <div className={'m-2'}>
                                                <Grid
                                                    url={'/griddocument/grid853-' + item._id}
                                                    perms={'853'}
                                                    disableModal={true}
                                                    GridTitle="Controle"
                                                    GridHeaders={[
                                                        { title: 'Data', field: 'Data', sort: true, className: 'w-1/4 text-left pl-1' },
                                                        { title: 'Nota Fiscal', field: 'nota', sort: true, className: 'w-1/4 pl-1 text-left border-l border-gray-300' },
                                                        { title: 'Tipo', field: 'tipo', sort: true, className: 'w-1/4 pl-1 text-left border-l border-gray-300' },
                                                        {
                                                            title: 'Quantidade',
                                                            field: 'quantidade',
                                                            sort: false,
                                                            className: 'w-1/4 pl-1 text-left border-l border-gray-300',
                                                            totalize: true,
                                                            totalTitle: 'Estoque',
                                                        },
                                                    ]}
                                                    LineDataPrint={(data) => {
                                                        return [
                                                            dateToString(data.data),
                                                            data.nota,
                                                            data.tipo,
                                                            <div className="inline-flex text-center">
                                                                {data.tipo === 'Entrada' ? <BiPlusCircle className="w-4 h-4 mt-1" /> : <BiMinusCircle className="w-4 h-4 mt-1" />}
                                                                {data.quantidade}
                                                            </div>,
                                                        ];
                                                    }}
                                                    TotalLineDataPrint={(data, GridTotal) => {
                                                        return GridTotal.map((totalizador) => {
                                                            if (totalizador.field === 'quantidade') {
                                                                if (data.tipo === 'Entrada') totalizador.value += parseInt(data.quantidade);
                                                                else totalizador.value -= parseInt(data.quantidade);
                                                            }
                                                            return true;
                                                        });
                                                    }}
                                                    onRenderForm={(GridModes, setFieldValue, values) => {
                                                        return (
                                                            <div className="w-full">
                                                                <DateInput name="data" label="Data" />
                                                                <Input name="nota" label="Nota Fiscal" />
                                                                <SelectInput
                                                                    name="tipo"
                                                                    label="Tipo"
                                                                    className={'w-1/3'}
                                                                    onChange={(e) => {
                                                                        setFieldValue('tipo', e.target.value);
                                                                    }}
                                                                    listOptions={[{ value: 'Entrada' }, { value: 'Saida' }]}
                                                                />
                                                                <Input name="quantidade" label="Quantidade" />
                                                            </div>
                                                        );
                                                    }}
                                                    validationSchema={() => {
                                                        return yup.object().shape({});
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                );
                            },
                        ]}
                        validationSchema={yup.object().shape({
                            descricao: yup.string().required(),
                            provedor: yup.string(),
                            unidade: yup.string(),
                            data: yup.date(),
                        })}
                    />
                </TabPanel>
                <TabPanel name="Anexos">
                    <GridAnexos url={`/attachment/form853`} perms="853" />
                </TabPanel>
            </TabsInput>
        </div>
    );
};
export const Form854 = () => {
    const System = useSystemContext();
    return (
        <div className="mx-2">
            <TabsInput>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div>🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Gestão</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel>
                    <FormInput
                        document="/document/form854"
                        perms="854"
                        title={System.getMenuTitle(854)}
                        initialValues={{
                            conteudo: '',
                        }}
                        validationSchema={yup.object().shape({
                            conteudo: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel>
                    <Grid
                        url={'/griddocument/grid854'}
                        perms={'854'}
                        clearAutoEdit={true}
                        GridHeaders={[
                            { title: 'Descrição', field: 'descricao', sort: true, className: 'w-4/6 text-left pl-1' },
                            { title: 'Etapa', field: 'etapa', sort: true, className: 'w-2/6 pl-1 text-left border-l border-gray-300' },
                        ]}
                        LineDataPrint={(data) => {
                            return [data.descricao, data.etapa];
                        }}
                        onRenderForm={(GridModes, SetFieldValue, values) => {
                            return (
                                <div>
                                    <div className="inline-flex w-full h-8 py-1 pl-2 justify-left bg-amarelo-eq">
                                        <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                                    </div>
                                    <Input name="descricao" label="Descrição" />
                                    <Input name="etapa" label="Etapa" />
                                    <Input name="situacao" label="Situação" />
                                    <Input name="controle" label="Controle" />
                                </div>
                            );
                        }}
                        validationSchema={yup.object().shape({
                            descricao: yup.string().required(),
                            etapa: yup.string(),
                            situacao: yup.string(),
                            controle: yup.string(),
                        })}
                    />
                </TabPanel>
            </TabsInput>
        </div>
    );
};
export const Form855 = () => {
    const System = useSystemContext();
    return (
        <div>
            <FormInput
                document="/document/form855"
                perms="855"
                title={System.getMenuTitle(855)}
                initialValues={{
                    conteudo: '',
                }}
                validationSchema={yup.object().shape({
                    conteudo: yup.string(),
                })}
                onRenderFields={() => {
                    return (
                        <div className="w-full">
                            <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                        </div>
                    );
                }}
            />
        </div>
    );
};
export const Form856 = () => {
    const System = useSystemContext();
    return (
        <div>
            <FormInput
                document="/document/form856"
                perms="856"
                title={System.getMenuTitle(856)}
                initialValues={{
                    conteudo: '',
                }}
                validationSchema={yup.object().shape({
                    conteudo: yup.string(),
                })}
                onRenderFields={() => {
                    return (
                        <div className="w-full">
                            <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                        </div>
                    );
                }}
            />
        </div>
    );
};
export const Form86 = () => {
    const System = useSystemContext();
    return (
        <div className="mx-2">
            <TabsInput>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div>🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Gestão</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel name="Principal">
                    <FormInput
                        document="/document/form86"
                        perms="86"
                        title={System.getMenuTitle(86)}
                        initialValues={{
                            conteudo: '',
                        }}
                        validationSchema={yup.object().shape({
                            conteudo: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel name="Liberação de P&S">
                    <Grid
                        url={'/griddocument/grid86'}
                        perms={'86'}
                        clearAutoEdit={true}
                        GridHeaders={[
                            { title: 'Etapa', field: 'etapa', sort: true, className: 'w-1/2 text-left pl-1' },
                            { title: 'Processo', field: 'processo', sort: true, className: 'w-1/2 pl-1 text-left border-l border-gray-300' },
                        ]}
                        LineDataPrint={(data) => {
                            return [data.etapa, data.processo];
                        }}
                        GridTitle={'8.6 - Liberação de P&S'}
                        onRenderForm={(GridModes, SetFieldValue, values) => {
                            return (
                                <div>
                                    <div className="inline-flex w-full h-8 py-1 pl-2 justify-left bg-amarelo-eq">
                                        <span>{!GridModes.insert_mode ? 'Editar' : 'Novo registro'}</span>
                                    </div>
                                    <Input name="etapa" label="Etapa" />
                                    <Input name="processo" label="Processo" />
                                    <Input name="criterio" label="Critério de Aceitação" />
                                    <Input name="docid" label="ID" />
                                </div>
                            );
                        }}
                        validationSchema={yup.object().shape({
                            etapa: yup.string().required(),
                            processo: yup.string(),
                            criterio: yup.string(),
                            docid: yup.string(),
                        })}
                    />
                </TabPanel>
            </TabsInput>
        </div>
    );
};
export const Form87 = () => {
    const System = useSystemContext();
    return (
        <div className="mx-2">
            <TabsInput>
                <TabList>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <div>🔷</div>
                        </div>
                    </Tab>
                    <Tab>
                        <div className="inline-flex justify-center">
                            <span>Anexos</span>
                        </div>
                    </Tab>
                </TabList>
                <TabPanel name="Principal">
                    <FormInput
                        document="/document/form87"
                        perms="87"
                        title={System.getMenuTitle(87)}
                        initialValues={{
                            conteudo: '',
                        }}
                        validationSchema={yup.object().shape({
                            conteudo: yup.string(),
                        })}
                        onRenderFields={() => {
                            return (
                                <div className="w-full">
                                    <TextInput name="conteudo" label="Conteúdo" className="w-full" rows="8" />
                                </div>
                            );
                        }}
                    />
                </TabPanel>
                <TabPanel>
                    <GridAnexos url="/attachment/form87" perms="87" />
                </TabPanel>
            </TabsInput>
        </div>
    );
};
